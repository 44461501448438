@tailwind base;
@tailwind components;
@tailwind utilities;

/* regular variant  */
@font-face {
  font-family: 'inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/public/fonts/Inter-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/public/fonts/Inter-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('/public/fonts/Inter-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'spaceGrotesk';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/public/fonts/SpaceGrotesk-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'spaceGrotesk';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('/public/fonts/SpaceGrotesk-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'spaceGrotesk';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('/public/fonts/SpaceGrotesk-Light.ttf') format('truetype');
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:#1A1919 ;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  color: #FEFFFF;
  /* font-family:'inter', sans-serif; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
